import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import { Button } from '~/components/common';
import { Stencil } from '~/components/common/Stencil';
import { WebConfLogo } from '~/components/common/WebConfLogo';
import { Desktop } from '~/components/helpers';

const Container = styled.section`
  display: grid;
  place-items: center;
  grid-template-rows: 1fr min-content;
  width: 100vw;
  height: 100vh;
  position: relative;

  & .svgLogo {
    height: 5rem;
    margin: 0 auto;
    display: block;
  }

  ${Desktop} {
    & .svgLogo {
      height: 12.5rem;
    }
  }
`;

const WhenAndWhere = styled.section`
  display: grid;
  place-items: center;
  font-family: Shader, sans-serif;
  gap: 1.5rem;
  text-transform: uppercase;

  ${Desktop} {
    grid-template-columns: 1fr 2px 2fr;
    margin-left: 27rem;
  }
`;

const When = styled.time`
  color: ${({ theme }) => theme.colors.landingHeaderDate};
  font-weight: 900;
  font-size: 2rem;

  span {
    display: block;
    font-size: 1rem;
  }
`;

const Where = styled.section`
  text-align: center;

  ${Desktop} {
    justify-self: start;
    text-align: left;
  }
`;

const WhereLocally = styled.address`
  color: ${({ theme }) => theme.colors.landingHeaderPlace};
  font-style: normal;
`;

const WhereGlobally = styled.span`
  color: ${({ theme }) => theme.colors.landingHeaderVirtualPlace};
`;

const Split = styled.span`
  display: none;
  width: 2px;
  height: 45px;
  background: ${({ theme }) => theme.colors.landingHeaderVerticalSeparator};

  ${Desktop} {
    display: block;
  }
`;

const ButtonSet = styled.menu`
  display: grid;
  grid-auto-flow: row;
  padding: 1rem;
  gap: 1.5rem;

  ${Desktop} {
    padding: 0;
    grid-auto-flow: column;
    margin-left: 27rem;
    justify-self: space-between;
    margin-right: 3rem;
  }
`;

const SubContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;
`;

const LearnMore = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2rem 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.hiDpi}) {
    padding: 0 0 4rem;
  }
`;

const LearnMoreArrow = styled.div`
  width: 0;
  height: 0;
  margin-top: 0.75rem;
  border-left: 1.375rem solid transparent;
  border-right: 1.375rem solid transparent;
  border-top: 1.375rem solid ${({ theme }) => theme.colors.landingHeaderLearnMoreArrow};
`;

// const sponsorUs = () => {
//   window.location = '/brief-2022.pdf';
// };

const goToYouTube = () => {
  window.location = 'https://www.youtube.com/watch?v=4Z3pbiplvws';
};

export const Header = ({ onLearnMore }) => (
  <>
    <Stencil />
    <Container>
      <SubContainer>
        <WebConfLogo className="svgLogo" />
        <WhenAndWhere>
          <When>
            18.nov
            <span>9:00 AM ARG</span>
          </When>
          <Split />
          <Where>
            <WhereLocally>Córdoba @ SUM IES21</WhereLocally>
            <WhereGlobally>Online @ Todo el mundo</WhereGlobally>
          </Where>
        </WhenAndWhere>
        <ButtonSet>
          <Link href="/tickets" passHref>
            <Button variant="primary" forwardedAs="a">
              Conseguí tu entrada
            </Button>
          </Link>
          <Button variant="sponsorUs" onClick={goToYouTube}>
            Mirá la conferencia en YouTube
          </Button>
        </ButtonSet>
      </SubContainer>
      <LearnMore onClick={onLearnMore}>
        Conocé más
        <LearnMoreArrow />
      </LearnMore>
    </Container>
  </>
);

Header.propTypes = {
  onLearnMore: PropTypes.func.isRequired,
};

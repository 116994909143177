import iesLogo from '~/public/logos/sponsors/IES.svg';
import nowstaLogo from '~/public/logos/sponsors/Nowsta.svg';
import howdyLogo from '~/public/logos/sponsors/Howdy.svg';
import coderHouseLogo from '~/public/logos/sponsors/Coderhouse.png';
import naranjaXLogo from '~/public/logos/sponsors/NaranjaX.png';
import nearsureLogo from '~/public/logos/sponsors/Nearsure.png';
import payoneerLogo from '~/public/logos/sponsors/Payoneer.svg';
import fuzzyFishLogo from '~/public/logos/sponsors/FuzzyFish.svg';

const SPONSORS = {
  jakarta: [
    {
      id: 'ies',
      name: 'Colegio Universitario IES',
      description: `En el Colegio Universitario IES, proponemos una formación de perfil práctico que vincula sólidamente
        situaciones profesionales con herramientas conceptuales de avanzada; una orientación para resolver
        problemas concretos con sentido ético; acuerdos de reciprocidad con instituciones públicas, privadas
        y del tercer sector; seminarios finales en cada carrera que hacen aportes concretos a las organizaciones
        y un amplio desarrollo de pasantías rentadas para alumnos en organizaciones de la provincia de Córdoba,
        nos permiten garantizar el cumplimiento de nuestra misión: Contribuir al desarrollo de la sociedad
        con Innovación, Exigencia y Seriedad, formando profesionales capaces de resolver los problemas
        de las organizaciones.`,
      logo: iesLogo,
      width: 600,
      height: 467,
      url: 'https://www.ies21.edu.ar',
      links: [
        {
          provider: 'instagram',
          handle: 'colegio.universitario.ies',
        },
        {
          provider: 'web',
          handle: 'https://www.ies21.edu.ar/inscripciones/?f=web',
        },
      ],
    },
  ],
  gold: [
    {
      id: 'nowsta',
      name: 'Nowsta',
      logo: nowstaLogo,
      width: 310,
      height: 310,
      resizeOnMobile: true,
      links: [
        {
          provider: 'twitter',
          handle: 'nowsta',
        },
        {
          provider: 'instagram',
          handle: 'nowsta',
        },
        {
          provider: 'linkedin',
          handle: 'nowsta',
        },
      ],
    },
  ],
  silver: [
    {
      id: 'howdy',
      name: 'Howdy',
      logo: howdyLogo,
      url: 'https://howdy.com',
      width: 240,
      height: 90,
      resizeOnMobile: true,
      links: [
        {
          provider: 'facebook',
          handle: 'wearehowdy0',
        },
        {
          provider: 'twitter',
          handle: 'wearehowdy_',
        },
        {
          provider: 'instagram',
          handle: 'wearehowdy_',
        },
        {
          provider: 'linkedin',
          handle: 'wearehowdy',
        },
      ],
    },
    {
      id: 'coderhouse',
      name: 'Coderhouse',
      logo: coderHouseLogo,
      url: 'https://coderhouse.com',
      width: 240,
      height: 55,
      resizeOnMobile: true,
      links: [
        {
          provider: 'twitter',
          handle: 'coderhouse',
        },
        {
          provider: 'instagram',
          handle: 'coderhouse',
        },
      ],
    },
  ],
  bronze: [
    {
      id: 'naranjax',
      name: 'Naranja X',
      logo: naranjaXLogo,
      width: 180,
      height: 50,
      url: 'https://naranjax.com',
      resizeOnMobile: true,
      links: [
        {
          provider: 'twitter',
          handle: 'naranjax',
        },
        {
          provider: 'instagram',
          handle: 'naranjax',
        },
        {
          provider: 'linkedin',
          handle: 'naranjax',
        },
      ],
    },
    {
      id: 'nearsure',
      name: 'Nearsure',
      logo: nearsureLogo,
      url: 'https://nearsure.com',
      width: 180,
      height: 45,
      resizeOnMobile: true,
      links: [
        {
          provider: 'instagram',
          handle: 'nearsure_',
        },
        {
          provider: 'twitter',
          handle: '_nearsure',
        },
        {
          provider: 'linkedin',
          handle: 'nearsure',
        },
        {
          provider: 'facebook',
          handle: 'nearsure',
        },
        {
          provider: 'web',
          handler: 'https://nearsure.com/careers',
        },
      ],
    },
    {
      id: 'payoneer',
      name: 'Payoneer',
      logo: payoneerLogo,
      url: 'https://payoneer.com',
      width: 180,
      height: 45,
      resizeOnMobile: true,
      links: [
        {
          provider: 'twitter',
          handle: 'payoneer_latam',
        },
        {
          provider: 'instagram',
          handle: 'payoneer_latam',
        },
      ],
    },
    {
      id: 'fuzzyfish',
      name: 'FuzzyFish',
      logo: fuzzyFishLogo,
      url: 'https://fuzzy.fish',
      width: 180,
      height: 35,
      resizeOnMobile: true,
      links: [
        {
          provider: 'twitter',
          handle: 'TheFuzzyFish',
        },
        {
          provider: 'instagram',
          handle: 'fuzzyfish_ok',
        },
      ],
    },
  ],
};

export const SPONSOR_CATEGORIES = Object.keys(SPONSORS);

export default SPONSORS;

/* eslint-disable max-len */
import { NATIONALITIES } from './nationality';
import { TALK_TYPES } from './talkTypes';

export const SPEAKERS = [
  {
    id: 'victoria-aganaras',
    variant: 'A',
    photo: '/speakers/victoria-aganaras.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Victoria',
    lastName: 'Argañaras',
    bio: 'Diseñadora Industrial y Front-end Developer, actualmente trabajando como dev para Mono Colombia. Apasionada por los detalles en la experiencia del usuario.',
    socialMediaHandles: {
      linkedin: 'argasvic',
    },
    talkType: TALK_TYPES.STANDARD,
    talkName: '¿Por qué un botón tiene forma de botón?',
    talkDescription:
      'Una charla sobre el recorrido histórico de las interfaces gráficas, para analizar el diseño web y entender algunas variables que definen los estilos actuales.',
    talkSchedule: ['2022-11-18T10:15:00.000-03:00', '2022-11-18T10:45:00.000-03:00'],
  },
  {
    id: 'dario-machi',
    variant: 'C',
    photo: '/speakers/dario-machi.png',
    nationality: NATIONALITIES.UY,
    firstName: 'Dario',
    lastName: 'Machi',
    bio: '**Darío Macchi**, WebDev por 20+ años. Trabaja en @AustinSoftware de mañana como Js FullStack dev; de tarde como Developer Advocate. En las noches tutorea proyectos finales de estudiantes de @UniversidadORT y da clases.',
    socialMediaHandles: {
      twitter: '_dariomac',
      linkedin: 'dariomac',
      instagram: '_dariomac',
    },
    talkType: TALK_TYPES.STANDARD,
    talkName: 'Don’t panic! Solucionando problemas recurrentes en equipos nuevos',
    talkDescription:
      'Luego de años aplicando las mismas soluciones a problemas recurrentes, documenté los 4 principales (técnicos, metodológicos, etc.) como patrones, con nombre, intención, estructura, como en el GOF.',
    talkSchedule: ['2022-11-18T11:00:00.000-03:00', '2022-11-18T11:30:00.000-03:00'],
  },
  {
    id: 'lara-diaz',
    variant: 'C',
    photo: '/speakers/lara-diaz.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Lara',
    lastName: 'Díaz',
    bio: 'Juego con React en @reciprocitylabs. Co-organizo una hermosa comunidad llamada @beerJsCba. Amo el café y los videojuegos.',
    socialMediaHandles: {
      twitter: 'lari_dev',
      linkedin: 'laradíaz',
      instagram: 'selene_l21',
    },
    talkType: TALK_TYPES.LIGHTENING,
    talkName: 'Método científico aplicado a la web',
    talkDescription:
      '"No te creo nada que esté más rápida", una charla de métricas, performance y experiencia de usuario.',
    talkSchedule: ['2022-11-18T12:00:00.000-03:00', '2022-11-18T12:15:00.000-03:00'],
  },
  {
    id: 'sergio-garzon',
    variant: 'C',
    photo: '/speakers/sergio-garzon.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Sergio',
    lastName: 'Garzon',
    bio: 'Desarrollador de software y de videojuegos. Docente de bases de datos MySQL y de varios lenguajes de programación en diferentes instituciones. Me apasiona la tecnología y el aprendizaje constante.',
    socialMediaHandles: {
      twitter: 'SergioGGarzn',
      linkedin: 'sergio-gabriel-garzon',
      instagram: 'sergiog90arg',
    },
    talkType: TALK_TYPES.LIGHTENING,
    talkName:
      'WebGL, la API de JavaScript que nos permite renderizar videojuegos 3D en navegadores',
    talkDescription:
      'WebGL, esta herramienta nos permite ejecutar videojuegos desarrollados con Unity, Unreal Engine y demás motores gráficos, en varios navegadores que lo soporten.',
    talkSchedule: ['2022-11-18T12:15:00.000-03:00', '2022-11-18T12:30:00.000-03:00'],
  },
  {
    id: 'martin-pastore',
    variant: 'B',
    photo: '/speakers/martin-pastore.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Martin',
    lastName: 'Pastore',
    bio: 'Actualmente me desempeño como desarrollador backend en Mundi, vengo principalmente del mundo del frontend pero este último año pude revertir eso. Me considero fana de JS (ahora más de TS) y me encanta todo lo que me obligue a aprender cosas nuevas.',
    socialMediaHandles: {
      twitter: 'bochap_',
      linkedin: 'martin-pastore-00a251110',
      instagram: 'bforbocha',
    },
    talkType: TALK_TYPES.STANDARD,
    talkName: 'Una intro muuuuy introductiva a event sourcing',
    talkDescription:
      'Seguramente escuchaste hablar de event sourcing alguna vez, pero nunca supiste cómo funciona. Bueno, yo tampoco, pero te invito a que lo descubramos juntos en esta intro al mundo de los eventos 💜',
    talkSchedule: ['2022-11-18T12:45:00.000-03:00', '2022-11-18T13:15:00.000-03:00'],
  },
  {
    id: 'facundo-corradini',
    variant: 'B',
    photo: '/speakers/facundo-corradini.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Facundo',
    lastName: 'Corradini',
    bio: 'Facundo es un desarrollador frontend, cocinero, deportista, y fundamentalista del mate amargo (no necesariamente en ese orden). De vez en cuando, también programa.',
    socialMediaHandles: {
      twitter: 'fcorradini',
      linkedin: 'facundo-corradini',
    },
    talkType: TALK_TYPES.STANDARD,
    talkName: 'Terapia de CSS',
    talkDescription:
      'Las cosas entre CSS y vos no están bien. Si vas para acá, CSS va para allá. A esta altura, jurarías que lo hace a propósito. Es hora de una intervención. Es hora de que CSS y vos aprendan a entenderse',
    talkSchedule: ['2022-11-18T15:00:00.000-03:00', '2022-11-18T15:30:00.000-03:00'],
  },
  {
    id: 'nicolas-passerino',
    variant: 'B',
    photo: '/speakers/nicolas-passerino.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Nicolas',
    lastName: 'Passerino',
    bio: '**Nicolás** es desarrollador front-end desde el 2016. Actualmente, trabaja con React y GraphQL pero tiene experiencia en Angular y otras librerías y frameworks. Es un barista en potencia amante del hardware y software.',
    socialMediaHandles: {
      linkedin: 'nicolas0mar',
    },
    talkType: TALK_TYPES.LIGHTENING,
    talkName: 'Como aplicar Diseño atómico en nuestro front-end',
    talkDescription:
      'Hay formas de organizar la estructura de nuestros componentes para evitar dolores de cabeza y yo quiero mostrar una que puede ayudar a hacer este trabajo menos tedioso incluso llamado Atomic Design.',
    talkSchedule: ['2022-11-18T15:45:00.000-03:00', '2022-11-18T16:00:00.000-03:00'],
  },
  {
    id: 'facundo-ozan',
    variant: 'A',
    photo: '/speakers/facundo-ozan.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Facundo',
    lastName: 'Ozan',
    bio: 'Tengo 23 años, trabajo como UX/UI Design y soy FrontEnd Developer. Me encanta el buen diseño y la programación. Crear productos innovadores es mi desafío diario.',
    socialMediaHandles: {
      twitter: 'Facu_OzanS',
      linkedin: 'facundo-ozan-senise-0996a318a',
      instagram: 'facu_ozan',
    },
    talkType: TALK_TYPES.LIGHTENING,
    talkName: 'Accesibilidad y UX ¿Para todxs?',
    talkDescription:
      'Accesibilidad y UX en tiempos modernos: ¿Cómo ser inclusivos? ¿Cómo mejorar mi UX desde la empatia con mi usuario? Eso y mucho más en esta charla de WebConf.',
    talkSchedule: ['2022-11-18T16:30:00.000-03:00', '2022-11-18T16:45:00.000-03:00'],
  },
  {
    id: 'santiago-persico',
    variant: 'A',
    photo: '/speakers/santiago-persico.png',
    nationality: NATIONALITIES.AR,
    firstName: 'Santiago',
    lastName: 'Pérsico',
    bio: 'Full Stack Coso Engineer. Opinologo de Slytherin 🐍. Leer changelogs de librerías me divierte. El tamaño de mi librería de Steam me llena de orgullo... y de verguenza. Fan del café, las gomitas y del helado de cereza. Co-founder de @SomosCodear.',
    socialMediaHandles: {
      twitter: 'spersico',
      linkedin: 'spersico',
    },
    talkType: TALK_TYPES.STANDARD,
    talkName: 'Programación basada en Slogans',
    talkDescription:
      "Cuando los principios de diseño de software se convierten en dogmas, quien sale lastimado es nuestro código. Una charla sobre DRY, Don't Reinvent the Wheel, y sus ventajas y problemas.",
    talkSchedule: ['2022-11-18T17:00:00.000-03:00', '2022-11-18T17:30:00.000-03:00'],
  },
];
export const WORKSHOPS = [];
